<template>
  <div class="region">
    <div class="container">
      <img src="@/assets/images/region/long.png">
      <h1>Краснодарский край</h1>
      <p>
        Краснодарский край — главный и крупнейший туристический регион на юге России и главный курортный регион страны.
        Комфортный климат и обилие подходящих для туризма природных локаций — Черноморское побережье, горы, Олимпийский
        горнолыжный кластер — все это создает условия, для посещения региона круглогодично.
        <br>
        В крае располагается большая часть исторического региона Кубань. Здесь находятся берега сразу двух морей -
        Черного и Азовского, и большое количество пляжных курортов, в том числе самые известные и популярные Сочи,
        Анапа, Геленджик. Помимо сезонного пляжного отдыха регион также привлекателен любителям природных
        достопримечательностей и походов. А горнолыжные курорты пользуются особой популярностью как у новичков, так и у
        профессионалов.
        <br>
        Курорты Большого Сочи
        <br>• Сочи - самый популярный и развитый курорт в России. Это большой город, в который помимо центра города
        входят и
        другие курорты - Адлер, Хоста.
        <br>• Адлер - наиболее южный район и самый популярный и развитый курорт города-курорта Сочи, находится в 30
        километрах от центра города. Здесь 8 километров галечных и песчано-галечных пляжей, много отелей и санаториев,
        главный аэропорт Сочи и современный ж/д вокзал.
        <br>• Имеретинский курорт или Сириус - молодой черноморский курорт в России, образованный из района Сочи и
        Адлера на
        территории Имеретинской низменности.
      </p>
      <div class="row images">
        <div class="col-sm-6 col-md-4 col-lg-3">
          <img src="@/assets/images/region/short-1.png">
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3">
          <img src="@/assets/images/region/short-2.png">
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3">
          <img src="@/assets/images/region/short-3.png">
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3">
          <img src="@/assets/images/region/short-4.png">
        </div>
      </div>
      <p style="margin-top: 30px">
        Отличное место для пляжного отдыха с протяженным галечным пляжем и хорошо развитой новой инфраструктурой.
        <br>
        • Кудепста – относительно тихий и спокойный пляжный микрорайон на окраине Адлера с не самыми лучшими, зато
        немноголюдными пляжами.
        <br>
        • Хоста - небольшой традиционный пляжный курорт рядом с Адлером, славится красивой природой, чистотой воздуха, и
        более мягким климатом. Развлечений и достопримечательностей здесь не много.
        <br>
        • Лазаревское - один из самых развитых курортов Сочи, выделяется чистой прозрачной водой, хорошей экологией,
        отличными условиями для отдыха любых категорий туристов.
        <br>
        • Лоо - один из наиболее развитых курортов в Лазаревском районе (помимо самого Лазаревского), отличается
        невысокими ценами за проживание.
        <br>
        • Уч-Дере - пляжный курорт и микрорайон. Пляж непосредственно самого Уч-Дере небольшой и почти дикий, подходит
        для отдыха с палатками.
        <br>
        • Дагомыс - небольшой район и пляжный курорт всего в 13 километрах от центра Сочи. Поселок хорошо развит, пляж
        галечный.
        <br>
        • Красная Поляна - горнолыжный курорт зимой и горный летом. Летом можно посетить в виде однодневной экскурсии,
        подняться в горы на канатных дорогах, или остановиться здесь на несколько дней для походов по горам или просто
        для смены обстановки.
      </p>
      <div class="d-flex justify-content-center">
        <a href="#" class="btn btn-info">Найти отели</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {}
  },
  async mounted() {

  },
  methods: {}
}
</script>

<style scoped>
.region {
  padding: 30px 15px 0 15px;
}

h1 {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #000000;
  margin: 30px 0 30px 0;
}

p {
  font-weight: 300;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}

.images img {
  margin-top: 30px;
}

a.btn {
  width: 230px;
  height: 40px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #000000;
  margin: 30px 0 30px 0;
}
</style>
